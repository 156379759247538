<template>
  <div class="ranking-bg" @scroll.native="isTopOnVisible">
    <div class="ranking-title">
      <ul>
        <li v-for="(rank, index) in rankList" :key="index" @click="checkActive(index)">
          <span :class="[activeIndex===index?'active':'']">{{rank.name}}</span>
        </li>
      </ul>
    </div>
    <div class="ranking-month box-padding" v-if="activeIndex === 0">
      <div>
        <ul>
          <li v-for="(month, index) in monthList" :key="index" @click="checkActiveMonth(index)" :class="[(activeMonth===index)&&(seasonNum >= index)?'active-month':'']" :disabled="seasonNum < index">{{month.name}}</li>
        </ul>
      </div>
    </div>
    <!--------以上月份tab---------->
    <div style="margin-top:0.4rem">
      <!--------以上包一个空div预留---------->
      <div class="ranking-box box-padding">
        <ul>
          <li>
            <span>{{rank.rankNum}}</span>
            <p>我的排名</p>
          </li>
          <li>
            <span>{{rank.totalPoints | level}}</span>
            <p>我的段位</p>
          </li>
          <li>
            <span>{{rank.totalPoints}}</span>
            <p>我的积分</p>
          </li>
        </ul>
      </div>
      <!-----以上月份排名------>
      <div class="ranking-table box-padding">
        <table>
          <tr>
            <th>排名</th>
            <th>手机号码</th>
            <th>段位</th>
            <th @click="showIntegralRuleDialog">积分 <img src="~images/dow-ceremony/mark.png" alt=""></th>
          </tr>
          <tr v-for="(rank, index) in rank.rankingVOList" :key="index">
            <td>
              <img src="~images/dow-ceremony/champion.png" v-if="index === 0">
              <img src="~images/dow-ceremony/runner-up.png" v-else-if="index === 1">
              <img src="~images/dow-ceremony/third.png" v-else-if="index === 2">
              <span v-else>{{index+1}}</span>
            </td>
            <td>{{rank.userPhone}}</td>
            <td>{{rank.points | level}}</td>
            <td>{{rank.points}}</td>
          </tr>
        </table>
      </div>
    </div>
    <!--------返回顶部------------>
    <div class="back-top" @click="toTop" v-if="isTop">
      回到<br/>顶部
    </div>
  </div>
</template>

<script>
import { integralRuleOfDialog, rankingsRule } from 'components/bomb-box/index'
import { pointsRank } from 'api/my-points'
import { ModalHelper } from 'utils'
import { getSessionStorage } from 'utils/store'
export default {
  data () {
    return {
      taskId: 0,
      activeIndex: 0,
      rank: {
        rankNum: '---',
        totalPoints: '---'
      },
      rankList: [
        { name: '赛期排行榜' },
        { name: '总排行榜' }
      ],
      activeMonth: 0,
      seasonNum: 0,
      isTop: false,
      monthList: [
        { name: '第一期' },
        { name: '第二期' },
        { name: '第三期' },
        { name: '第四期' },
        { name: '第五期' }
      ]
    }
  },
  computed: {
  },
  mounted () {
    this.taskId = JSON.parse(getSessionStorage('task')).id
    this.getMonthRank(this.taskId)
    this.activeMonth = JSON.parse(getSessionStorage('task')).id
    window.addEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll (e) {
      let scrollTop = e.target.scrollingElement.scrollTop
      let height = document.documentElement.clientHeight || document.body.clientHeight
      if (scrollTop > height) {
        this.isTop = true
      } else {
        this.isTop = false
      }
    },
    toTop () {
      document.body.scrollTop = 0
      document.documentElement.scrollTop = 0
    },
    getMonthRank (taskId) {
      let params = {}
      if (this.activeIndex === 0) {
        params = { seasonId: taskId }
      } else {
        params = {}
      }
      pointsRank(JSON.stringify(params)).then(res => {
        if (res.data.result !== 0) {
          this.$createToast({
            txt: res.data.desc,
            type: 'error',
            time: 2000
          }).show()
          return
        }
        this.rank = res.data.data || {}
        this.rank.rankNum = res.data.data.rankNum || 0
        this.rank.totalPoints = res.data.data.totalPoints || 0
        this.seasonNum = res.data.data.currentSeason
      })
    },
    showIntegralRuleDialog () {
      if (this.activeIndex === 0) {
        ModalHelper.onOpen()
        this.$createDialog({
          showClose: true,
          onClose: () => {
            ModalHelper.onClose()
          }
        }, integralRuleOfDialog).show()
      } else if (this.activeIndex === 1) {
        ModalHelper.onOpen()
        this.$createDialog({
          showClose: true,
          onClose: () => {
            ModalHelper.onClose()
          }
        }, rankingsRule).show()
      }
    },
    checkActive (index) {
      this.activeIndex = index
      if (this.activeIndex === 0) {
        this.activeMonth = this.taskId
      }
      this.getMonthRank(this.taskId)
    },
    checkActiveMonth (index) {
      if (this.seasonNum < index) {
        return
      }
      this.activeMonth = index
      this.getMonthRank(index)
    }
  }
}
</script>

 <style lang="scss" scoped>
@import "~styles/index.scss";
body {
  background: #f0f0f0;
}
.ranking-bg {
  background: #f0f0f0;
  min-height: 100vh;

  .ranking-title {
    background: #fff;
    width: px2rem(750px);
    height: px2rem(88px);
    display: inline-table;
    line-height: px2rem(88px);
    & ul {
      display: inline-table;
      width: 100%;
      font-size: px2rem(28px);
      border-bottom: 1px solid $color-line;
      & li {
        display: table-cell;
        width: 49%;
        border-right: 1px solid $color-line;
        color: $color-text;
        text-align: center;
        height: px2rem(40px);
        line-height: px2rem(40px);
        display: inline-block;
        & span {
          height: px2rem(38px);
          width: px2rem(175px);
          line-height: px2rem(38px);
        }
      }
      & li:last-child {
        border-right: none;
      }
    }
    .active {
      color: $color-highlight;
      border-bottom: 2px solid $color-highlight;
      padding: 0 0 px2rem(24px) 0;
      width: px2rem(175px);
      height: px2rem(37px);
      display: inline-block;
      font-weight: bold;
    }
  }
  .ranking-month {
    width: px2rem(690px);
    background: #fff;
    border: 1px solid $color-line;
    color: #999;
    font-size: px2rem(28px);
    display: inline-block;
    margin: px2rem(30px) px2rem(30px) px2rem(-7.5px) px2rem(30px);
    box-sizing: border-box;
    border-radius: 6px;
    li {
      width: 20%;
      text-align: center;
      float: left;
      height: px2rem(70px);
      line-height: px2rem(70px);
      border-left: 1px solid $color-line;
      box-sizing: border-box;
    }
    li:first-child {
      border-left: none;
    }
    // li:last-child{
    //   width: px2rem(138px);
    // }
    .active-month {
      background: #e4e4e4;
      color: $color-text;
    }
  }
  .ranking-box {
    background: url(~images/dow-ceremony/min-bg.png);
    background-size: 100%;
    width: px2rem(690px);
    height: px2rem(130px);
    font-size: px2rem(28px);
    & li {
      float: left;
      color: #fff;
      border-right: 1px solid #fff;
      border-color: rgba(255, 255, 255, 0.2);
      height: px2rem(70px);
      width: px2rem(189px);
      box-sizing: border-box;
      margin: px2rem(28px) 0 ;
      width: 33.33%;
      text-align: center;
      & span {
        font-size: px2rem(32px);
        font-weight: bold;
        line-height: px2rem(50px);
        height: px2rem(50px);
        // display: -webkit-box;
        text-align: center;
        width: 33.33%;
      }
      & p {
        font-size: px2rem(24px);
        color: rgba(255, 255, 255, 0.6);
      }
    }
    & li:last-child {
      border-right: none;
    }
  }
  .ranking-table {
    display: inline-table;
    font-size: px2rem(28px);
    background: #fff;
    border: 1px solid $color-line;
    width: px2rem(690px);
    box-sizing: border-box;
    margin: px2rem(30px);
    border-radius: 6px;
    table {
      width: 100%;
      th {
        width: px2rem(690px);
        height: px2rem(97px);
        font-size: px2rem(28px);
        color: #363636;
        font-weight: bold;
        line-height: px2rem(97px);
        position: relative;
        & img {
          width: px2rem(28px);
          height: px2rem(28px);
          // margin: -11% auto 0;
          // transform: translateY(-11%);
          // margin-right: 1.2rem;
          display: block;
          position: absolute;
          bottom: px2rem(34px);
          right: px2rem(25px);
        }
      }
      tr {
        border-bottom: 1px solid $color-line;
        td {
          width: 25%;
          text-align: center;
          line-height: px2rem(90px);
          height: px2rem(90px);
          vertical-align: middle;
          position: relative;
          & img {
            width: px2rem(54px);
            height: px2rem(60px);
            display: inline-block;
            position: absolute;
            bottom: px2rem(16px);
            left: px2rem(50px);
          }
        }
      }
      tr:last-child {
        border-bottom: none;
      }
    }
  }
  .back-top {
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.15);
    position: fixed;
    width: px2rem(100px);
    height: px2rem(100px);
    border-radius: 100%;
    color: #363636;
    font-size: px2rem(28px);
    right: px2rem(30px);
    bottom: px2rem(30px);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    // font-weight: bold;
    line-height: px2rem(30px);
  }
}
</style>
